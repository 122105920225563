import React from "react"
import { Router } from "@reach/router"
import { login, isAuthenticated, getProfile, logout } from "../utils/auth"
import Admin from "../components/Admin"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

const AdminContainer = () => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()

  console.log("ADMIN", user, user["https://fundmyprivatecampaign.com/is_admin"])
  if (user["https://fundmyprivatecampaign.com/is_admin"] !== true) {
    logout()
    return <p>Redirecting to login...</p>
  }

  return (
    <Layout>
      <Helmet>
				<title>Admin - My Private Fund </title>
			</Helmet>
      <Router>
        <Admin path="/admin" tab="home" />
        <Admin path="/admin/donations" tab="donations" />
        <Admin path="/admin/users" tab="users" />
        <Admin path="/admin/expenses" tab="expenses" />
        <Admin path="/admin/details" tab="details" />
      </Router>
    </Layout>
  )
}

export default AdminContainer