import React from "react"
import { Box, Split, SplitChild, AdminTitle } from "../../UI"
import { Form, Field } from "../../Form"
import { Link } from "gatsby"
import moment from "moment"

export default ({ donations, updateState }) => {

    const completed = donations.filter((d) => d.isCompleted)
    const notCompleted = donations.filter((d) => !d.isCompleted)

	const add = async (values) => {   
        console.log("VALUES", values)
		donations.push(values)
        updateState({ donations })
	}

	return <>
   
        <h2>Donations</h2>	
        {completed.length === 0 && <p>No completed donations to show.</p>}

        <Split>
            <SplitChild grow="7">
                <p>These are donation that where completed using Stripe or added by an admin.</p>
                {completed.map(({ _id, amount, user, addedByAdmin, anonymous, created_at }, index) => {
                    return <Box key={_id}>
                        <Split>
                            <SplitChild>
                                <AdminTitle>{amount}$</AdminTitle>
                                {user != null && <p>{user.name} ({user.email})</p>}
                                <small>Donation ID: {_id}</small>
                            </SplitChild>
                            <SplitChild right>
                                <small><strong>{moment(created_at).format("dddd, MMMM Do")}</strong></small><br/>
                                <small>
                                    <span>{addedByAdmin ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i>} Added by an Admin</span>
                                </small>
                                <br />
                                <small>
                                    <span>{anonymous ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i>} This donation is anonymous</span>
                                </small>
                            </SplitChild>
                        </Split>
                    </Box>
                })}

                <h2>Not Completed donations</h2>	
                <p>These are donation that where started but were never completed by the user.</p>
                {completed.length === 0 && <p>No completed donations to show.</p>}

                {notCompleted.map(({ _id, amount, user, created_at }, index) => {
                    return <Box key={_id}>
                        <Split>
                            <SplitChild>
                                <AdminTitle>{amount}$</AdminTitle>
                                {user != null && <p>{user.name} ({user.email})</p>}
                                <small>Donation ID: {_id}</small>
                            </SplitChild>
                            <SplitChild right>
                                <small><strong>{moment(created_at).format("dddd, MMMM Do")}</strong></small><br/>
                            </SplitChild>
                        </Split>
                    </Box>
                })}

            </SplitChild>
            <SplitChild grow="3">
                <Box>
                    <AdminTitle>Total: {completed.reduce((total, curr) => total+=curr.amount, 0)}$</AdminTitle>
                </Box>
                <Box>
                    <Form
                        action="admin-add-donation"
                        submit="Add new donation"
                        error="Error while adding donation."
                        onSubmit={add}>
                            <Field type="number" name="amount" label="Amount" defaultValue={100} required />
                            <Field type="checkbox" name="anonymous" label="Make the donation anonymous. This will hide the name in the details page." />
                            <Field type="text" name="user_id" label="User ID" required />
                            <small><Link to="/admin/users">Create or find a user</Link></small>
                            <Field type="hidden" name="cid" defaultValue="247256867411067410" />
                    </Form>
                </Box>
            </SplitChild>
        </Split>

	</>
}