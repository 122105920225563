import React, { useState } from "react"
import { Box, Split, SplitChild, AdminTitle } from "../../UI"
import Button from "../../Button"
import { postInfo } from "../../../utils/fetch"
import moment from "moment"

export default ({ users, updateState }) => {
    let [loading, setLoading] = useState(null)	
	const needsApproval = users.filter(d => !d.isValidated )

	const approve = async (_id) => {   
        setLoading(_id)     
        const data = await postInfo("admin-approve", { _id })
        console.log("NEW USER", data)
        const index = users.findIndex(d => d._id === _id)
		users[index] = data
        updateState({ users })
        setLoading(null)
	}

	return <>
   
        <h2>User Approval</h2>	
        {needsApproval.length === 0 && <p>No new accounts to validate.</p>}

        {needsApproval.map(({ _id, name, email, isValidated, created_at, donations }, index) => {
            return <Box key={_id}>
                <Split>
                    <SplitChild>
                        <AdminTitle>{name}</AdminTitle>
                        <p>{email}</p>
                        <Button auto loading={loading === _id} onClick={() => approve(_id)}>Approve the account</Button>
                    </SplitChild>
                    <SplitChild right>
                        <small><strong>{moment(created_at).format("dddd, MMMM Do")}</strong></small><br/>
                        <small>
                            <span>{isValidated ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i>} Account Validated</span>
                        </small>
                        <br/>
                        <small>
                            <span>{donations.data.length > 0 ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i>} {donations.data.length || 0} Donations</span>
                        </small>
                    </SplitChild>
                </Split>
            </Box>
        })}
	</>
}