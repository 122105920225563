import React, { Component } from "react"
import { Link } from "gatsby"
import { ToastProvider } from 'react-toast-notifications'
import { setPageInfo } from "../../utils/fetch"
import s from "./admin.module.scss"

import Approvals from "./Pages/Approvals"
import Donations from "./Pages/Donations"
import Users from "./Pages/Users"
import Expenses from "./Pages/Expenses"
import Details from "./Pages/Details"

export default class Admin extends Component {

	state = {
		donations: [],
		expenses: [],
		users: [],
		campaign: {},
	}

	async componentDidMount() {
		await setPageInfo.call(this, `get-details-admin?id=247256867411067410`)
	}

	updateState = (newState) => {
		this.setState({ ...newState })
	}

	render() {
		const { donations, expenses, users, campaign } = this.state
		const { tab } = this.props

		return <ToastProvider>
		<>
			<header className={s.header}>
					<div className={s.container}>
						<h1>Elena’s Support Group</h1>
						<div className={s.tabs}>
							<Link to="/admin" className={tab === "home" ? s.active : null}>Dashboard</Link>
							<Link to="/admin/donations" className={tab === "donations" ? s.active : null}>Donations</Link>
							<Link to="/admin/users" className={tab === "users" ? s.active : null}>Users</Link>
							<Link to="/admin/expenses" className={tab === "expenses" ? s.active : null}>Expenses</Link>
							<Link to="/admin/details" className={tab === "details" ? s.active : null}>Details</Link>
						</div>
					</div>
			</header>
			<main className={`${s.container} ${s.main}`}>
				{tab === "home" && <Approvals users={users} updateState={this.updateState} />}
				{tab === "donations" && <Donations donations={donations} updateState={this.updateState}  />}
				{tab === "users" && <Users users={users} updateState={this.updateState}  />}
				{tab === "expenses" && <Expenses expenses={expenses} updateState={this.updateState}  />}
				{tab === "details" && <Details campaign={campaign} updateState={this.updateState}  />}
			</main>
		</>
		</ToastProvider>
	}
}