import React from "react"
import { Box, Split, SplitChild } from "../../UI"
import { Form, Field } from "../../Form"

export default ({ campaign, updateState }) => {

	const update = async (values) => {   
		campaign = values
        updateState({ campaign })
	}

	return <>
   
        <h2>Details</h2>	

        <Split>
            <SplitChild grow="7">
                <Box>
                    <Form
                        action="admin-update-campaign"
                        submit="Update"
                        error="Error while updating the details."
                        onSubmit={update}>
                            <Field type="text" name="name_fr" label="Name (fr)" defaultValue={campaign.name_fr} required />
                            <Field type="text" name="name_en" label="Name (en)" defaultValue={campaign.name_en} required />
                            <Field type="textarea" name="desc_fr" label="Public Description (fr)" rows="9" defaultValue={campaign.desc_fr} required />
                            <Field type="textarea" name="desc_en" label="Public Description (en)" rows="9" defaultValue={campaign.desc_en} required />
                            <Field type="textarea" name="desc_complete_fr" label="Public Description (fr)" rows="18" defaultValue={campaign.desc_complete_fr} required />
                            <Field type="textarea" name="desc_complete_en" label="Public Description (en)" rows="18" defaultValue={campaign.desc_complete_en} required />
                            <Field type="text" name="img" label="Blurred Image" defaultValue={campaign.img} required />
                            <Field type="text" name="img_complete" label="Image" defaultValue={campaign.img_complete} required />
                            <Field type="hidden" name="cid" defaultValue="247256867411067410" />
                    </Form>
                </Box>
            </SplitChild>
        </Split>

	</>
}