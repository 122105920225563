import React from "react"
import { Box, Split, SplitChild, AdminTitle } from "../../UI"
import { Form, Field } from "../../Form"
import moment from "moment"

export default ({ users, updateState }) => {	

	const add = async (values) => {   
        console.log("USER", values)
		users.push(values)
        updateState({ users })
	}

	return <>
   
        <h2>Users</h2>	
        {users.length === 0 && <p>No new accounts to validate.</p>}

        <Split>
            <SplitChild grow="7">
                {users.map(({ _id, name, email, isValidated, addedByAdmin, created_at, donations }, index) => {
                    return <Box key={_id}>
                        <Split>
                            <SplitChild>
                                <AdminTitle>{name}</AdminTitle>
                                <p>{email}</p>
                                <small>User ID: {_id}</small>
                            </SplitChild>
                            <SplitChild right>
                                <small><strong>{moment(created_at).format("dddd, MMMM Do")}</strong></small><br/>
                                <small>
                                    <span>{isValidated ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i>} Account Validated</span>
                                </small>
                                <br />
                                <small>
                                    <span>{addedByAdmin ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i>} Added by an Admin</span>
                                </small>
                                <br/>
                                <small>
                                    <span>{donations.data.length > 0 ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i>} {donations.data.length || 0} Donations</span>
                                </small>
                            </SplitChild>
                        </Split>
                    </Box>
                })}
            </SplitChild>
            <SplitChild grow="3">
                <Box>
                    <Form
                        action="admin-add-user"
                        submit="Add new user"
                        error="Error while adding user. Does he already exist?"
                        toast={{ text: "User sucessfully added." }}
                        onSubmit={add}>
                            <Field type="text" name="name" label="Name" required />
                            <Field type="email" name="email" label="Email (optinal)" />
                    </Form>
                </Box>
            </SplitChild>
        </Split>
	</>
}