import React from "react"
import { Box, Split, SplitChild, AdminTitle } from "../../UI"
import { Form, Field } from "../../Form"
import moment from "moment"

export default ({ expenses, updateState }) => {

	const add = async (values) => {   
		expenses.push(values)
        updateState({ expenses })
	}

	return <>
   
        <h2>Expenses</h2>	
        {expenses.length === 0 && <p>No expenses to show.</p>}

        <Split>
            <SplitChild grow="7">
                {expenses.sort((a,b) => a.date > b.date).map(({ _id, amount, desc, date }, index) => {
                    return <Box key={_id}>
                        <Split>
                            <SplitChild grow="7">
                                <AdminTitle>{amount.toFixed(2)}$</AdminTitle>
                                <p>{desc}</p>
                                <small>Expense ID: {_id}</small>
                            </SplitChild>
                            <SplitChild grow="3" right>
                                <small><b>{moment(date).format("dddd, MMMM Do")}</b></small><br/>
                            </SplitChild>
                        </Split>
                    </Box>
                })}
            </SplitChild>
            <SplitChild grow="3">
                <Box>
                    <AdminTitle>Total: {expenses.reduce((total, curr) => total+=curr.amount, 0).toFixed(2)}$</AdminTitle>
                </Box>
                <Box>
                    <Form
                        action="admin-add-expense"
                        submit="Add new expense"
                        error="Error while adding expense."
                        onSubmit={add}>
                            <Field type="number" name="amount" label="Amount" defaultValue={100} required />
                            <Field type="textarea" name="desc" label="Description" required />
                            <Field type="date" name="date" label="Date" required />
                            <Field type="hidden" name="cid" defaultValue="247256867411067410" />
                    </Form>
                </Box>
            </SplitChild>
        </Split>

	</>
}